import { Controller } from "@hotwired/stimulus";
import { Modal } from "bootstrap";

export default class extends Controller {
  static values = {
    newHome: Boolean,
    userCountry: String,
    cartsData: String
  };

  url =
    "https://www.youtube.com/embed/CGAxjjV6rSs?autoplay=1&amp;modestbranding=1&amp;showinfo=0%22";
  // videoModal = new Modal(this.videoModalTarget);
  static targets = ["videoModal", "videoFrame", "dataSrc", "cart"];

  connect() {
    this.cartsByCountryUpdate();
    this.sendOpenEvents();
    let observe = true;
    const imageObserver = new IntersectionObserver(
      (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && observe) {
            this.dataSrcTargets.forEach(
              (image) => (image.src = image.dataset.src)
            );
            observe = false;
          }
        });
      },
      {
        rootMargin: "50px 0px 50px"
      }
    );
    this.dataSrcTargets.forEach((img) => imageObserver.observe(img));
  }

  closeVideoModal() {
    this.videoModal.hide();
    this.videoFrameTarget.src = "";
  }

  openVideoModal(e) {
    this.videoModal.show();
    this.videoFrameTarget.src = this.url;
    this.gtagClick(e);
  }

  gtagClick(e) {
    if (e.params.label === "try_for_free") {
      dataLayer.push({
        event: `${this.newHomeValue ? "new-" : ""}button_Try_For_Free`,
      });
    } else if (e.params.label === "next_step_to_qr_code") {
      dataLayer.push({
        event: `${this.newHomeValue ? "new-" : ""}button_Next_step_to_QR_code`,
      });
    } else if (e.params.label === "button_reffer") {
      dataLayer.push({
        event: e.params.label,
      });
    }
    let page = e.view.window.location.pathname.replace(/^\//g, "");
    if (page === "") {
      page = "home";
    }
    const event = `${this.newHomeValue ? "new-" : ""}${page}/${e.params.type}/${
      e.params.label
    }`;
    gtag("event", event, {
      event_category: page,
      event_label: event,
      value: 1
    });
  }

  generateNowBtn() {
    dataLayer.push({
      event: `${this.newHomeValue ? "new-" : ""}button_Generate_QR_Now`
    });
  }

  sendOpenEvents() {
    const event = `open_${this.newHomeValue ? "new" : "old"}_main`;
    dataLayer.push({ event });
    gtag("event", event, {
      event_category: "home",
      event_label: event,
      value: 1
    });
  }

  cartsByCountryUpdate() {
    const updateCart = (cart, cartData, isTextCart = true) => {
      if (isTextCart) {
        cart.querySelector(".header-text").innerText = cartData.title;
        let tooltipTrigger = cart.querySelector(".header-img-question");
        if (cartData.popover!=null && tooltipTrigger!=null) {
          tooltipTrigger.setAttribute('data-bs-content', cartData.popover);
          let popover = new bootstrap.Popover(tooltipTrigger);
        } else if(tooltipTrigger!=null  ) {
          tooltipTrigger.classList.add("d-none");
        }
      }
      cart.querySelector(".header-img").src =
        !isTextCart && cartData.img2 ? cartData.img2 : cartData.img;
      cart.querySelector(".header-img").alt = cartData.label;
      cart.href = cartData.href;

      cart.dataset.newLandingLabelParam = cartData.label;

    };


    // const country = this.userCountryValue;
    const cartsData = JSON.parse(this.cartsDataValue);

    updateCart(this.cartTargets[0], cartsData.link);
    updateCart(this.cartTargets[1], cartsData.image);
    updateCart(this.cartTargets[2], cartsData.pdf);
    updateCart(this.cartTargets[3], cartsData.whatsapp, false);
    updateCart(this.cartTargets[4], cartsData.audio);
    updateCart(this.cartTargets[5], cartsData.vcard);
    updateCart(this.cartTargets[6], cartsData.map, false);
    updateCart(this.cartTargets[7], cartsData.linkList);
    updateCart(this.cartTargets[8], cartsData.text);
    updateCart(this.cartTargets[9], cartsData.instagram, false);
    updateCart(this.cartTargets[10], cartsData.youtube, false);
    updateCart(this.cartTargets[11], cartsData.email);
    updateCart(this.cartTargets[12], cartsData.store);


  }
  }
