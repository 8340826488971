import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {

  connect() {

  }

  async generateDispute(e) {
    e.preventDefault();
    let target = e.target;
    this.disableLink(target);
    let tr = target.closest("tr");
    let userId = tr.querySelector(".user-detail-table-info").getAttribute("data-user-id");

    try {
      let { data } = await axios.get(`/generate-dispute?id=${userId}`);
    } catch (e) {
      alert(e.response.data.error);
      this.enableLink(target);
      return 1;
    }

    let disputeDownload = tr.querySelector(".disputeDownload");
    this.enableLink(target);
    this.enableLink(disputeDownload);
  }

  disableLink(link) {
    link.style.pointerEvents = "none";  // Disable link
    link.style.opacity = "0.5";         // Make it look disabled
  }

  enableLink(link) {
    link.style.pointerEvents = "auto";  // Enable link interaction
    link.style.opacity = "1";           // Reset the opacity to normal
  }
}
