class PaymentMiddlewareUrlService
{
    premiumYearType = 'premium_year';
    premiumMonthType = 'premium_month';
    liteYearType = 'lite_year';
    liteMonthType = 'lite_month';
    storageYearType = 'storage_year';
    storageMonthType = 'storage_month';

    subscriptionTypes = [
        this.premiumYearType,
        this.premiumMonthType,
        this.liteYearType,
        this.liteMonthType,
        this.storageYearType,
        this.storageMonthType,
    ];

    premiumUrl = '/subscription/payment-middleware';
    liteUrl = '/subscription/lite-middleware';
    storageUrl = '/subscription/storage-middleware';

    subscriptionTypeParam = 'type'; // Тип подписки (месячная/годовая)
    currencyParam = 'currency'; // Валюта покупаемой подписки
    couponParam = 'coupon'; // Промокод
    quantityOrderParam = 'quantity_order'; // Количество покупаемых планов
    quantityUnitsParam = 'quantity_units'; // Количество единиц покупаемых mb (для storage подписки)

    /**
     * @param subscriptionType {string}
     * @return boolean
     */
    isValidSubscriptionType(subscriptionType)
    {
        return this.subscriptionTypes.includes(subscriptionType);
    }

    /**
     * @param subscriptionType {string}
     * @return boolean
     */
    isPremiumSubscriptionType(subscriptionType)
    {
        return [this.premiumYearType, this.premiumMonthType].includes(subscriptionType);
    }

    /**
     * @param subscriptionType {string}
     * @return boolean
     */
    isLiteSubscriptionType(subscriptionType)
    {
        return [this.liteYearType, this.liteMonthType].includes(subscriptionType);
    }

    /**
     * @param subscriptionType {string}
     * @return boolean
     */
    isStorageSubscriptionType(subscriptionType)
    {
        return [this.storageYearType, this.storageMonthType].includes(subscriptionType);
    }

    /**
     * @param subscriptionType {string}
     * @param currency {string|null}
     * @param quantityOrder {number|string|null}
     * @param coupon {string|null}
     * @return string
     */
    getPremiumMiddlewareUrl({
        subscriptionType,
        currency= null,
        quantityOrder= null,
        coupon= null,
    }) {
        return this._addQueryParamToUrl({
            url: this.premiumUrl,
            subscriptionType: subscriptionType,
            currency: currency,
            quantityOrder: quantityOrder,
            coupon: coupon,
        });
    }

    /**
     * @param currency {string|null}
     * @param quantityOrder {number|string|null}
     * @param coupon {string|null}
     * @return string
     */
    getPremiumYearMiddlewareUrl({
        currency= null,
        quantityOrder= null,
        coupon= null,
    }) {
        return this.getPremiumMiddlewareUrl({
            subscriptionType: this.premiumYearType,
            currency: currency,
            quantityOrder: quantityOrder,
            coupon: coupon
        });
    }

    /**
     * @param currency {string|null}
     * @param quantityOrder {number|string|null}
     * @param coupon {string|null}
     * @return string
     */
    getPremiumMonthMiddlewareUrl({
        currency= null,
        quantityOrder= null,
        coupon= null,
    }) {
        return this.getPremiumMiddlewareUrl({
            subscriptionType: this.premiumMonthType,
            currency: currency,
            quantityOrder: quantityOrder,
            coupon: coupon
        });
    }

    /**
     * @param subscriptionType {string}
     * @param currency {string|null}
     * @param entryId {number|string|null}
     * @param quantityOrder {number|string|null}
     * @param coupon {string|null}
     * @return string
     */
    getLiteMiddlewareUrl({
        subscriptionType,
        entryId= null,
        currency = null,
        quantityOrder= null,
        coupon= null,
    }) {
        let liteMiddlewareUrl = this.liteUrl;
        if (entryId) {
            liteMiddlewareUrl += `/${entryId}`;
        } else {
            liteMiddlewareUrl += `/null`;
        }

        return this._addQueryParamToUrl({
            url: liteMiddlewareUrl,
            subscriptionType: subscriptionType,
            currency: currency,
            quantityOrder: quantityOrder,
            coupon: coupon,
        });
    }

    /**
     * @param currency {string|null}
     * @param entryId {number|string|null}
     * @param quantityOrder {number|string|null}
     * @param coupon {string|null}
     * @return string
     */
    getLiteYearMiddlewareUrl({
        currency= null,
        entryId= null,
        quantityOrder= null,
        coupon= null,
    }) {
        return this.getLiteMiddlewareUrl({
            subscriptionType: this.liteYearType,
            entryId: entryId,
            currency: currency,
            quantityOrder: quantityOrder,
            coupon: coupon
        });
    }

    /**
     * @param currency {string|null}
     * @param entryId {number|string|null}
     * @param quantityOrder {number|string|null}
     * @param coupon {string|null}
     * @return string
     */
    getLiteMonthMiddlewareUrl({
        currency= null,
        entryId= null,
        quantityOrder= null,
        coupon= null,
    }) {
        return this.getLiteMiddlewareUrl({
            subscriptionType: this.liteMonthType,
            entryId: entryId,
            currency: currency,
            quantityOrder: quantityOrder,
            coupon: coupon
        });
    }

    /**
     * @param subscriptionType {string}
     * @param currency {string|null}
     * @param quantityUnits {number|string|null}
     * @param quantityOrder {number|string|null}
     * @param coupon {string|null}
     * @return string
     */
    getStorageMiddlewareUrl({
        subscriptionType,
        currency= null,
        quantityUnits= null,
        quantityOrder= null,
        coupon= null,
    }) {
        return this._addQueryParamToUrl({
            url: this.storageUrl,
            subscriptionType: subscriptionType,
            currency: currency,
            quantityUnits: quantityUnits,
            quantityOrder: quantityOrder,
            coupon: coupon,
        });
    }

    /**
     * @param currency {string|null}
     * @param quantityUnits {number|string|null}
     * @param quantityOrder {number|string|null}
     * @param coupon {string|null}
     * @return string
     */
    getStorageYearMiddlewareUrl({
        currency= null,
        quantityUnits= null,
        quantityOrder= null,
        coupon= null,
    }) {
        return this.getStorageMiddlewareUrl({
            subscriptionType: this.storageYearType,
            currency: currency,
            quantityUnits: quantityUnits,
            quantityOrder: quantityOrder,
            coupon: coupon
        });
    }

    /**
     * @param currency {string|null}
     * @param quantityUnits {number|string|null}
     * @param quantityOrder {number|string|null}
     * @param coupon {string|null}
     * @return string
     */
    getStorageMonthMiddlewareUrl({
        currency= null,
        quantityUnits= null,
        quantityOrder= null,
        coupon= null,
    }) {
        return this.getStorageMiddlewareUrl({
            subscriptionType: this.storageMonthType,
            currency: currency,
            quantityUnits: quantityUnits,
            quantityOrder: quantityOrder,
            coupon: coupon
        });
    }

    /**
     * @private
     * @param url {string}
     * @param subscriptionType {string}
     * @param currency {string|null}
     * @param quantityUnits {number|string|null}
     * @param quantityOrder {number|string|null}
     * @param coupon {string|null}
     * @return string
     */
    _addQueryParamToUrl({
        url,
        subscriptionType,
        currency= null,
        quantityOrder = null,
        coupon = null,
        quantityUnits = null
    }) {
        url += `?${this.subscriptionTypeParam}=${subscriptionType}`;

        if (currency) {
            url += `&${this.currencyParam}=${currency}`;
        }
        if (quantityOrder) {
            url += `&${this.quantityOrderParam}=${quantityOrder}`;
        }
        if (coupon) {
            url += `&${this.couponParam}=${coupon}`;
        }
        if (quantityUnits) {
            url += `&${this.quantityUnitsParam}=${quantityUnits}`;
        }

        return url;
    }
}

export default (new PaymentMiddlewareUrlService());
