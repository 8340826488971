import { Controller } from "@hotwired/stimulus";
import axios from "axios";
import paymentMiddlewareUrlService from "../../service/payment/payment_middleware_url_service";

export default class extends Controller {
  static values = {
    coupon: String,
    newHome: Boolean,
  };

  static targets = [
    "originalLiteYearPrice",
    "originalPremiumYearPrice",
    "originalLiteMonthPrice",
    "originalPremiumMonthPrice",
    "liteYear",
    "premiumYear",
    "lite",
    "premium",
    "finalLiteYearPrice",
    "finalPremiumYearPrice",
    "saveLiteYear",
    "saveLite",
    "savePremium",
    "free",
    "monthlyPlan",
    "annualPlan",
    "getPremiumYearBtn",
    "getPremiumMonthBtn",
    "getLiteYearBtn",
    "getLiteMonthBtn",
  ];

  symbol = {
    usd: "$",
    aud: "A$",
    brl: "R$",
    eur: "€",
    gbp: "£",
    idr: "Rp",
    inr: "₹",
    sar: "SR",
    thb: "฿",
    uah: "₴",
    vnd: "₫",
  };

  plansData = {};

  connect() {
    let planPriceRequestData = {};
    if (this.couponValue) {
      planPriceRequestData = {
        coupon: this.couponValue,
      };
    }
    axios.post("/get-all-plans", planPriceRequestData)
      .then(({ data }) => {
        this.plansData = data;
        this.setData("usd");
      })
    ;
  }

  setData(currency) {
    if (this.couponValue !== '') {
      this.setOriginalPrice(
          this.originalLiteYearPriceTargets,
          this.plansData["lite_year"],
          currency,
          true,
      );
      this.setOriginalPrice(
          this.originalPremiumYearPriceTargets,
          this.plansData["premium_year"],
          currency,
          true,
      );
      this.setOriginalPrice(
          this.originalLiteMonthPriceTargets,
          this.plansData["lite_month"],
          currency,
      );
      this.setOriginalPrice(
          this.originalPremiumMonthPriceTargets,
          this.plansData["premium_month"],
          currency,
      );

      this.setFinalPrice(
          this.finalLiteYearPriceTargets,
          this.plansData["lite_year"],
          currency,
      );
      this.setFinalPrice(
          this.finalPremiumYearPriceTargets,
          this.plansData["premium_year"],
          currency,
      );
    }

    this.setPrice(
      this.liteYearTargets,
      this.plansData["lite_year"],
      currency,
      true,
    );
    this.setPrice(
      this.premiumYearTargets,
      this.plansData["premium_year"],
      currency,
      true,
    );
    this.setPrice(
      this.liteTargets,
      this.plansData["lite_month"],
      currency,
      false,
    );
    this.setPrice(
      this.premiumTargets,
      this.plansData["premium_month"],
      currency,
      false,
    );
    this.calculateSavePrice(currency);
    this.freeTargets.forEach(
      (target) => (target.innerHTML = `${this.symbol[currency]}0`)
    );
    this.setCheckoutLinks(currency);
  }

  setOriginalPrice(targets, data, currency, isYear) {
    const originalPrice = data.find((item) => item.currency === currency)['price_per_unit'];
    targets.forEach((target) => {
      const amount = isYear ? (originalPrice / 12).toFixed(2) : originalPrice;
      target.innerHTML = `${this.symbol[currency]}${amount}`;
    });
  }

  setPrice(targets, data, currency, isYear) {
    const price = data.find((item) => item.currency === currency).amount;
    targets.forEach((target) => {
      const amount = isYear ? (price / 12).toFixed(2) : price;
      target.innerHTML = `${this.symbol[currency]}${amount}`;
    });
  }

  setFinalPrice(targets, data, currency) {
    const price = data.find((item) => item.currency === currency)['amount'];
    targets.forEach((target) => {
      target.innerHTML = `${this.symbol[currency]}${price}`;
    });
  }

  calculateSavePrice(currency) {
    const liteYearData = this.plansData["lite_year"].find((item) => item.currency === currency);
    const liteMonthData = this.plansData["lite_month"].find((item) => item.currency === currency);
    const premiumYearData = this.plansData["premium_year"].find((item) => item.currency === currency);
    const premiumMonthData = this.plansData["premium_month"].find((item) => item.currency === currency);

    const liteAmountYear = liteYearData['amount'];
    const liteMonthPricePerUnit = liteMonthData['price_per_unit'];
    const liteMonthPriceFor12Month = liteMonthPricePerUnit * 12;
    const liteYearProfit = this.roundPrice(liteMonthPriceFor12Month - liteAmountYear);

    const premiumAmountYear = premiumYearData['amount'];
    const premiumMonthPricePerUnit = premiumMonthData['price_per_unit'];
    const premiumMonthPriceFor12Month = premiumMonthPricePerUnit * 12;
    const premiumYearProfit = this.roundPrice(premiumMonthPriceFor12Month - premiumAmountYear);

    this.saveLiteTargets.forEach((target) => {
      target.innerHTML = `${this.symbol[currency]}${liteYearProfit}`;
    });

    this.savePremiumTargets.forEach((target) => {
      target.innerHTML = `${this.symbol[currency]}${premiumYearProfit}`;
    });
  }

  roundPrice(price)
  {
    price = Number(price.toFixed(2));
    if (price % 1 === 0) {
      return Math.round(price);
    }

    return price;
  }

  toggle(event) {
    this.setData(event.target.value);
  }

  selectMonthPlan() {
    this.monthlyPlanTargets.forEach((item) => {
      item.classList.add("d-none");
    });
    this.annualPlanTargets.forEach((item) => {
      item.classList.remove("d-none");
    });
  }

  selectAnnualPlan() {
    this.monthlyPlanTargets.forEach((item) => {
      item.classList.remove("d-none");
    });
    this.annualPlanTargets.forEach((item) => {
      item.classList.add("d-none");
    });
  }

  setCheckoutLinks(currency) {
    this.getPremiumYearBtnTargets.forEach((item) => {
      item.href = this.createMiddlewareUrl('premium_year', currency);
    });
    this.getPremiumMonthBtnTargets.forEach((item) => {
      item.href = this.createMiddlewareUrl('premium_month', currency);
    });
    this.getLiteYearBtnTargets.forEach((item) => {
      item.href = this.createMiddlewareUrl('lite_year', currency);
    });
    this.getLiteMonthBtnTargets.forEach((item) => {
      item.href = this.createMiddlewareUrl('lite_month', currency);
    });
  }

  /**
   * @param subscriptionType {string}
   * @param currency {string}
   */
  createMiddlewareUrl(subscriptionType, currency) {
    if (paymentMiddlewareUrlService.isPremiumSubscriptionType(subscriptionType)) {
      return paymentMiddlewareUrlService.getPremiumMiddlewareUrl({
        subscriptionType: subscriptionType,
        currency: currency,
        coupon: this.couponValue,
      });
    } else if (paymentMiddlewareUrlService.isLiteSubscriptionType(subscriptionType)) {
      return paymentMiddlewareUrlService.getLiteMiddlewareUrl({
        subscriptionType: subscriptionType,
        currency: currency,
        coupon: this.couponValue,
      });
    }

    return '';
  }

  getPlanBtn(e) {
    dataLayer.push({
      event: `${this.newHomeValue ? "new-" : ""}button_get_${e.params.label}`,
    });
  }

  multiSubscribeNavigate(e) {
    const locale = document.querySelector('[data-locale]').getAttribute('data-locale')
    document.cookie = `multiSub=1; path=${locale === 'en' ? "" : `/${locale}`}/subscription`;
    window.location.href = e.target.dataset.href;
  }
}
