import { Controller } from "@hotwired/stimulus";
import { webView } from "../../../assets/variables/webView";
import { getCookie } from "../../hooks/helpers";

export default class extends Controller {
  static values = {
    newHome: Boolean,
    userCountry: String,
    generatorTypes: String

  };
  static targets = ["type"];

  connect() {
    this.typesByCountryUpdate();
    this.checkNeedLogoutWhen2Fa();
    this.checkWebView();

    function setCookie(cName, cValue, expDays) {
      let date = new Date();
      date.setTime(date.getTime() + expDays * 24 * 60 * 60 * 1000);
      const expires = "expires=" + date.toUTCString();
      document.cookie = cName + "=" + cValue + "; " + expires + "; path=/";
    }

    document.querySelectorAll(".lang").forEach(function(el) {
      el.addEventListener("click", function(e) {
        e.preventDefault();
        setCookie("user_local", e.currentTarget.hreflang, 360);
        window.location.href = e.currentTarget.href;
      });
    });
  }

  gtagClick(e) {
    if (e.params.label === "create_qr_code") {
      dataLayer.push({
        event: `${
          this.newHomeValue ? "new-" : ""
        }button_create_qr_code_general`
      });
    }
    if (e.params.label === "qr_scanner") {
      dataLayer.push({
        event: `${this.newHomeValue ? "new-" : ""}button_QR_Scanner`
      });
    }
    let page = e.view.window.location.pathname.replace(/^\//g, "");
    if (page === "") {
      page = "home";
    }
    const event = `${this.newHomeValue ? "new-" : ""}${page}/${e.params.type}/${
      e.params.label
    }`;
    gtag("event", event, {
      event_category: page,
      event_label: event,
      value: 1
    });
  }

  loginBtn() {
    dataLayer.push({ event: `${this.newHomeValue ? "new-" : ""}button_Login` });
  }

  createNewBtn() {
    dataLayer.push({
      event: `${this.newHomeValue ? "new-" : ""}button_Create_New_QR_Code`
    });
  }

  checkWebView() {
    if (webView) {
      document.querySelector("#logo-image")?.removeAttribute("href");
      ["faq", "userDomain", "importCsv", "login", "language"].forEach((item) =>
        document.querySelectorAll(`#${item}`).forEach((item) => item.remove())
      );
    }
    if (getCookie("ios_web_view") === "1") {
      document
        .querySelectorAll("#paymentMenuItem")
        .forEach((item) => item.remove());
    }
  }

  checkNeedLogoutWhen2Fa() {
    if (window.location.pathname === "/2fa") {
      let timerId = setInterval(() => {
        if (window.location.pathname === "/2fa") {
          const form = document.querySelector("[data-login-time]");
          if (form) {
            let userLoginTime = form.getAttribute("data-login-time");
            let currentTime = Date.now();
            if (userLoginTime) {
              userLoginTime = Date.parse(userLoginTime);
              if (userLoginTime < currentTime) {
                window.location.href = "/logout";
              }
            }
          }
        } else {
          clearInterval(timerId);
        }
      }, 60000);
    }
  }

  typesByCountryUpdate() {
    if (!this.generatorTypesValue) {
      return;
    }

    const updateTypes = (type, generatorType) => {
      type.querySelector(".type-title").innerText = generatorType.title;
      type.querySelector(".type-img").setAttribute("href", generatorType.img);
      type.href = generatorType.href;
    };

    const country = this.userCountryValue;
    const generatorTypes = JSON.parse(this.generatorTypesValue);


    updateTypes(this.typeTargets[0], generatorTypes.link);
    updateTypes(this.typeTargets[1], generatorTypes.pdf);
    updateTypes(this.typeTargets[2], generatorTypes.image);
    updateTypes(this.typeTargets[3], generatorTypes.app);
    updateTypes(this.typeTargets[4], generatorTypes.text);
    updateTypes(this.typeTargets[5], generatorTypes.map);
    updateTypes(this.typeTargets[6], generatorTypes.wifi);
    updateTypes(this.typeTargets[7], generatorTypes.audio);
    updateTypes(this.typeTargets[8], generatorTypes.whatsapp);
    updateTypes(this.typeTargets[9], generatorTypes.youtube);

    if (country === "KR") {

      updateTypes(this.typeTargets[3], generatorTypes.app);
      updateTypes(this.typeTargets[4], generatorTypes.instagram);
      updateTypes(this.typeTargets[5], generatorTypes.audio);
      updateTypes(this.typeTargets[6], generatorTypes.youtube);
      updateTypes(this.typeTargets[7], generatorTypes.wifi);
      updateTypes(this.typeTargets[8], generatorTypes.text);
      updateTypes(this.typeTargets[9], generatorTypes.form);
    } else if (country === "CA") {

      updateTypes(this.typeTargets[3], generatorTypes.app);
      updateTypes(this.typeTargets[4], generatorTypes.youtube);
      updateTypes(this.typeTargets[5], generatorTypes.instagram);
      updateTypes(this.typeTargets[6], generatorTypes.email);
      updateTypes(this.typeTargets[7], generatorTypes.text);
      updateTypes(this.typeTargets[8], generatorTypes.audio);
      updateTypes(this.typeTargets[9], generatorTypes.form);
    } else if (country === "GB") {

      updateTypes(this.typeTargets[3], generatorTypes.whatsapp);
      updateTypes(this.typeTargets[4], generatorTypes.vcard);
      updateTypes(this.typeTargets[5], generatorTypes.text);
      updateTypes(this.typeTargets[6], generatorTypes.app);
      updateTypes(this.typeTargets[7], generatorTypes.youtube);
      updateTypes(this.typeTargets[8], generatorTypes.email);
      updateTypes(this.typeTargets[9], generatorTypes.instagram);

    } else if (country === "IT") {

      updateTypes(this.typeTargets[3], generatorTypes.audio);
      updateTypes(this.typeTargets[4], generatorTypes.instagram);
      updateTypes(this.typeTargets[5], generatorTypes.whatsapp);
      updateTypes(this.typeTargets[6], generatorTypes.wifi);
      updateTypes(this.typeTargets[7], generatorTypes.text);
      updateTypes(this.typeTargets[8], generatorTypes.map);
      updateTypes(this.typeTargets[9], generatorTypes.youtube);
    } else if (country === "BR") {

      updateTypes(this.typeTargets[2], generatorTypes.whatsapp);
      updateTypes(this.typeTargets[3], generatorTypes.image);
      updateTypes(this.typeTargets[4], generatorTypes.app);
      updateTypes(this.typeTargets[5], generatorTypes.instagram);
      updateTypes(this.typeTargets[6], generatorTypes.youtube);
      updateTypes(this.typeTargets[7], generatorTypes.text);
      updateTypes(this.typeTargets[8], generatorTypes.wifi);
      updateTypes(this.typeTargets[9], generatorTypes.audio);
    } else if (country === "DE") {

      updateTypes(this.typeTargets[3], generatorTypes.audio);
      updateTypes(this.typeTargets[4], generatorTypes.text);
      updateTypes(this.typeTargets[5], generatorTypes.app);
      updateTypes(this.typeTargets[6], generatorTypes.instagram);
      updateTypes(this.typeTargets[7], generatorTypes.map);
      updateTypes(this.typeTargets[8], generatorTypes.youtube);
      updateTypes(this.typeTargets[9], generatorTypes.wifi);
    } else if (country === "KW") {

      updateTypes(this.typeTargets[3], generatorTypes.whatsapp);
      updateTypes(this.typeTargets[4], generatorTypes.map);
      updateTypes(this.typeTargets[5], generatorTypes.instagram);
      updateTypes(this.typeTargets[6], generatorTypes.youtube);
      updateTypes(this.typeTargets[7], generatorTypes.app);
      updateTypes(this.typeTargets[8], generatorTypes.email);
      updateTypes(this.typeTargets[9], generatorTypes.vcard);
    } else if (country === "MX") {

      updateTypes(this.typeTargets[3], generatorTypes.whatsapp);
      updateTypes(this.typeTargets[4], generatorTypes.map);
      updateTypes(this.typeTargets[5], generatorTypes.youtube);
      updateTypes(this.typeTargets[6], generatorTypes.form);
      updateTypes(this.typeTargets[7], generatorTypes.facebook);
      updateTypes(this.typeTargets[8], generatorTypes.wifi);
      updateTypes(this.typeTargets[9], generatorTypes.instagram);
    } else if (country === "OM") {

      updateTypes(this.typeTargets[3], generatorTypes.audio);
      updateTypes(this.typeTargets[4], generatorTypes.map);
      updateTypes(this.typeTargets[5], generatorTypes.instagram);
      updateTypes(this.typeTargets[6], generatorTypes.youtube);
      updateTypes(this.typeTargets[7], generatorTypes.whatsapp);
      updateTypes(this.typeTargets[8], generatorTypes.text);
      updateTypes(this.typeTargets[9], generatorTypes.wifi);
    } else if (country === "ES") {

      updateTypes(this.typeTargets[3], generatorTypes.audio);
      updateTypes(this.typeTargets[4], generatorTypes.youtube);
      updateTypes(this.typeTargets[5], generatorTypes.whatsapp);
      updateTypes(this.typeTargets[6], generatorTypes.text);
      updateTypes(this.typeTargets[7], generatorTypes.map);
      updateTypes(this.typeTargets[8], generatorTypes.instagram);
      updateTypes(this.typeTargets[9], generatorTypes.wifi);
    } else if (country === "FR") {

      updateTypes(this.typeTargets[3], generatorTypes.audio);
      updateTypes(this.typeTargets[4], generatorTypes.youtube);
      updateTypes(this.typeTargets[5], generatorTypes.app);
      updateTypes(this.typeTargets[6], generatorTypes.instagram);
      updateTypes(this.typeTargets[7], generatorTypes.vcard);
      updateTypes(this.typeTargets[8], generatorTypes.text);
      updateTypes(this.typeTargets[9], generatorTypes.map);
    } else if (country === "TH") {

      updateTypes(this.typeTargets[3], generatorTypes.map);
      updateTypes(this.typeTargets[4], generatorTypes.facebook);
      updateTypes(this.typeTargets[5], generatorTypes.youtube);
      updateTypes(this.typeTargets[6], generatorTypes.form);
      updateTypes(this.typeTargets[7], generatorTypes.text);
      updateTypes(this.typeTargets[8], generatorTypes.app);
      updateTypes(this.typeTargets[9], generatorTypes.excel);
    } else if (country === "JA") {
      updateTypes(this.typeTargets[3], generatorTypes.youtube);
      updateTypes(this.typeTargets[4], generatorTypes.email);
      updateTypes(this.typeTargets[5], generatorTypes.audio);
      updateTypes(this.typeTargets[6], generatorTypes.app);
      updateTypes(this.typeTargets[7], generatorTypes.text);
      updateTypes(this.typeTargets[8], generatorTypes.wifi);
      updateTypes(this.typeTargets[9], generatorTypes.twitter);
    } else if (country === "AE") {

      updateTypes(this.typeTargets[2], generatorTypes.whatsapp);
      updateTypes(this.typeTargets[3], generatorTypes.image);
      updateTypes(this.typeTargets[4], generatorTypes.map);
      updateTypes(this.typeTargets[5], generatorTypes.vcard);
      updateTypes(this.typeTargets[6], generatorTypes.instagram);
      updateTypes(this.typeTargets[7], generatorTypes.email);
      updateTypes(this.typeTargets[8], generatorTypes.text);
      updateTypes(this.typeTargets[9], generatorTypes.app);
    } else if (country === "SA") {

      updateTypes(this.typeTargets[3], generatorTypes.whatsapp);
      updateTypes(this.typeTargets[4], generatorTypes.map);
      updateTypes(this.typeTargets[5], generatorTypes.multiurl);
      updateTypes(this.typeTargets[6], generatorTypes.email);
      updateTypes(this.typeTargets[7], generatorTypes.text);
      updateTypes(this.typeTargets[8], generatorTypes.instagram);
      updateTypes(this.typeTargets[9], generatorTypes.vcard);
    } else if (country === "US") {

      updateTypes(this.typeTargets[3], generatorTypes.whatsapp);
      updateTypes(this.typeTargets[4], generatorTypes.youtube);
      updateTypes(this.typeTargets[5], generatorTypes.text);
      updateTypes(this.typeTargets[6], generatorTypes.instagram);
      updateTypes(this.typeTargets[7], generatorTypes.map);
      updateTypes(this.typeTargets[8], generatorTypes.vcard);
      updateTypes(this.typeTargets[9], generatorTypes.app);
    } else if (country === "CH") {
      updateTypes(this.typeTargets[2], generatorTypes.audio);
      updateTypes(this.typeTargets[3], generatorTypes.image);
      updateTypes(this.typeTargets[4], generatorTypes.youtube);
      updateTypes(this.typeTargets[5], generatorTypes.email);
      updateTypes(this.typeTargets[6], generatorTypes.instagram);
      updateTypes(this.typeTargets[7], generatorTypes.text);
      updateTypes(this.typeTargets[8], generatorTypes.app);
      updateTypes(this.typeTargets[9], generatorTypes.vcard);
    }
  }
}
