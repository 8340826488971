import { Controller } from "@hotwired/stimulus";
import axios from "axios";

export default class extends Controller {
  static values = { page: Number }
  static targets = ["cell", "input", "alertErrorTarget","reviewBlock", "viewMoreBtn"];

  connect() {
    this.pageValue = 1;
    const saveButton = this.element.querySelector(".save-btn");
    if (saveButton) {
      saveButton.disabled = true;
    }
    this.isDoubleClick = true;
  }

  edit(event) {
    const cell = event.target;
    const originalValue = cell.getAttribute("data-old-value");
    const originalValueInput = cell.innerText.trim();
    const field = cell.getAttribute("data-field");

    if (cell.querySelector("input")) return;

    const input = document.createElement("input");
    input.type = field === "rating" ? "number" : "text";
    input.value = field === "rating" ? cell.getAttribute("data-value") : originalValueInput;
    input.className = "form-control";
    input.style.width = "100%";

    if (field === "rating") {
      input.max = "5";
      input.min = "1";
    }

    cell.innerHTML = "";
    cell.appendChild(input);
    input.focus();

    input.addEventListener("blur", this.saveChanges.bind(this, cell, originalValue, field));
    input.addEventListener("keydown", this.handleKeydown.bind(this, input));
  }

  saveChanges(cell, originalValue, field, event) {
    if (this.isDoubleClick) {
      this.isDoubleClick = false;
      return;
    }

    const newValue = event.target.value.trim();

    const originalStrValue = String(originalValue);
    const newStrValue = String(newValue);

    setTimeout(() => {
      if (newStrValue !== originalStrValue) {
        cell.innerHTML = field === "rating" ? this.renderStars(newStrValue) : newStrValue;
        if (field === "rating") {
          cell.setAttribute("data-value", newStrValue);
        }
      } else {
        cell.innerHTML = field === "rating" ? this.renderStars(originalStrValue) : originalStrValue;
        if (field === "rating") {
          const originalValueRating = cell.getAttribute("data-old-value");
          cell.setAttribute("data-value", originalValueRating);
        }
      }
      this.isDoubleClick = true;

      let tr = cell.closest("tr");
      let titleElement = tr.querySelector("[data-field=\"title\"]");
      let contentElement = tr.querySelector("[data-field=\"content\"]");
      let ratingElement = tr.querySelector("[data-field=\"rating\"]");
      let authorElement = tr.querySelector("[data-field=\"author\"]");

      let title = titleElement.innerText.trim();
      let content = contentElement.innerText.trim();
      let rating = ratingElement.getAttribute("data-value");
      let author = authorElement.innerText.trim();

      let titleOld = titleElement.getAttribute("data-old-value");
      let contentOld = contentElement.getAttribute("data-old-value");
      let ratingOld = ratingElement.getAttribute("data-old-value");
      let authorOld = authorElement.getAttribute("data-old-value");

      if (
        title === titleOld
        && content === contentOld
        && rating === ratingOld
        && author === authorOld
      ) {
        this.disableSaveButton(tr);
      } else {
        this.enableSaveButton(tr);
      }

    }, 0);
  }

  enableSaveButton(row) {
    const saveButton = row.querySelector(".save-btn");
    saveButton.disabled = false;
  }

  disableSaveButton(row) {
    const saveButton = row.querySelector(".save-btn");
    saveButton.disabled = true;
  }

  handleKeydown(input, event) {
    if (event.key === "Enter") {
      input.blur();
    }
  }

  renderStars(rating) {
    const filledStars = "★".repeat(rating);
    const emptyStars = "☆".repeat(5 - rating);
    return filledStars + emptyStars;
  }

  async save(e) {
    let button = e.target;
    let tr = button.closest("tr");
    let id = tr.getAttribute("data-id");
    let title = tr.querySelector("[data-field=\"title\"]").innerText.trim();
    let content = tr.querySelector("[data-field=\"content\"]").innerText.trim();
    let rating = tr.querySelector("[data-field=\"rating\"]").getAttribute("data-value");
    let author = tr.querySelector("[data-field=\"author\"]").innerText.trim();
    let locale = document.querySelector('[data-locale]').getAttribute('data-locale');

    const formData = new FormData();
    formData.append("title", title);
    formData.append("content", content);
    formData.append("rating", rating);
    formData.append("author", author);
    formData.append('locale', locale)

    try {
      const { data } = await axios.post(`/admin/review/${id}/edit`, formData);
      this.showSuccessAlert("Success updated");
    } catch (e) {
      if (e.response.data) {
        this.showErrorAlert(e.response.data);
      }
    }
  }

  async delete(e) {
    let button = e.target;
    let tr = button.closest("tr");
    let id = tr.getAttribute("data-id");

    try {
      const { data } = await axios.delete(`/admin/review/${id}/delete`);
      location.reload();
    } catch (e) {
      if (e.response.data) {
        this.showErrorAlert(e.response.data);
      }
    }
  }

  showErrorAlert(message) {
    let errorElement = document.querySelector("#errorReview");
    errorElement.classList.remove("d-none");
    errorElement.innerHTML = message;
    setTimeout(() => {
      errorElement.classList.add("d-none");
      errorElement.innerHTML = "";
    }, 7000);
  }

  showSuccessAlert(message) {
    let successElement = document.querySelector("#successReview");
    successElement.classList.remove("d-none");
    successElement.innerHTML = message;
    setTimeout(() => {
      successElement.classList.add("d-none");
      successElement.innerHTML = "";
    }, 7000);
  }

  search(e) {
    let button = e.target;
    let div = button.closest("div");
    let input = div.querySelector("input");

    if (input.value) {
      let currentUrl = new URL(window.location);

      currentUrl.searchParams.set("q", input.value);

      window.location.href = currentUrl.toString();
    }
  }
  async fetchNextPage() {
    try {
      const url = `/ajax/review?page=${this.pageValue}&limit=3`;
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`error: ${response.status}`);
      }
      const data = await response.json();
      if (data.reviews.length < 3) {
        this.hideLoadMoreButton();
      }
      const container = this.reviewBlockTarget;
      data.reviews.forEach(review => {

        const reviewHTML = `
          <div class="p-5 bg-white rounded mb-4">
            <div class="d-flex justify-content-between mb-4 align-items-start">
              <div>
                <div class="d-flex justify-content-between">
                  <div class="d-flex mb-3 align-items-center">
                    <img src="/static/pages/reviews/stars-${review.rating}.svg" loading="lazy" alt="stars">
                    <span class="ms-2 pt-1 review-stars">${review.rating}</span>
                  </div>
                  <img src="/static/pages/reviews/quotes.svg" loading="lazy" class="img-fluid d-md-none"
                       alt="quotes" width="32" height="28">
                </div>
                <p class="fs-4 fw-bold mb-0">${review.title}</p>
              </div>
              <img src="/static/pages/reviews/quotes.svg" loading="lazy" class="img-fluid d-md-block d-none" alt="quotes">
            </div>
            <p class="mb-4">${review.content}</p>
            <div class="d-flex align-items-center">
              <div class="me-3">
                <img src="/static/pages/reviews/user.svg" height="54" width="53" alt="User Picture" loading="lazy">
              </div>
              <div>
                <p class="fw-bold fs-7 mb-0">${review.author}</p>
              </div>
            </div>
          </div>
        `;

        container.insertAdjacentHTML("beforeend", reviewHTML);
      });

      this.pageValue += 1;
    } catch (error) {
      console.error("error", error);
    }
  }
  hideLoadMoreButton() {
    const button = this.viewMoreBtnTarget;
    if (button) {
      button.classList.add("d-none");
    }
  }
}
